import React from "react";
import Hero from "../sections/landing/Hero";
import Content1 from "../sections/landing/Content1";
import Content2 from "../sections/landing/Content2";
import Feature2 from "../sections/landing/Feature2";
import Fact from "../sections/landing/Fact";
import Pricing from "../sections/landing/Pricing";
import CTA from "../sections/landing/CTA";
import AppStores from "../sections/landing/AppStores";
import PageWrapper from "../components/PageWrapper";

const IndexPage = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Hero />
        <Fact />
        <Content1 />
        <Feature2 />
        <Content2 />
        <Pricing />
        <AppStores />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
