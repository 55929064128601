import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import imgAppStore from "../../assets/image/png/app-store.png";
import imgPlay from "../../assets/image/png/google-play.png";

const SectionStyled = styled(Section)``;

const ButtonContainer = styled(Box)`
  margin-top: 35px;
  display: flex;
  justify-content: center;
`;

const ButtonApp = styled.a`
  padding: 0 9px;
  transition: 0.4s;
  &:visited {
    text-decoration: none;
  }
  &:hover {
    transform: translateY(-8px);
  }
`;

const AppStores = () => (
  <>
    <SectionStyled className="position-relative" bg="secondary">
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="7" xl="6">
            <div className="text-center">
              <Title>Get the app now</Title>

              <ButtonContainer>
                <ButtonApp href="https://play.google.com/store/apps/details?id=com.petaler">
                  <img src={imgPlay} alt="" className="img-fluid" />
                </ButtonApp>
                <ButtonApp href="https://apps.apple.com/us/app/petaler/id1546724193">
                  <img src={imgAppStore} alt="" className="img-fluid" />
                </ButtonApp>
              </ButtonContainer>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default AppStores;
