import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import GlobalContext from "../../context/GlobalContext";

import { device } from "../../utils";
import imgL1HeroPhone from "../../assets/image/png/mobile.png";

const TopCard = styled(Box)`
  width: 305px;
  box-shadow: ${({ theme }) => `0 32px 34px ${theme.colors.shadow}`};
  position: absolute;
  top: 14%;
  left: 0;
  @media ${device.md} {
    left: -13%;
  }
  @media ${device.lg} {
    left: 2%;
  }
`;

const BottomCard = styled(Box)`
  width: 305px;
  box-shadow: ${({ theme }) => `0 32px 34px ${theme.colors.shadow}`};
  position: absolute;
  bottom: 12%;
  right: 0;
  @media ${device.md} {
    right: -13%;
  }
  @media ${device.lg} {
    right: -5%;
  }
  @media ${device.xl} {
    right: -33%;
  }
`;

const ImgRight = styled.img`
  max-width: 50%;
  -webkit-filter: drop-shadow(
    ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`}
  );
  filter: drop-shadow(${({ theme }) => `0 52px 54px ${theme.colors.shadow}`});
  @media ${device.sm} {
    max-width: 60%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
`;

const Hero = () => {
  const gContext = useContext(GlobalContext);

  const openVideoModal = (e) => {
    e.preventDefault();
    gContext.toggleVideoModal();
  };

  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section className="position-relative" bg="lightShade">
        <div className="pt-5"></div>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col lg="5" md="8" sm="9" className="order-lg-2">
              <div className="text-center text-lg-right position-relative">
                <div
                  className="img-main"
                  data-aos="fade-down"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <ImgRight src={imgL1HeroPhone} alt="" className="img-fluid" />
                </div>

                <TopCard
                  bg="red"
                  p="18px"
                  borderRadius={8}
                  className="d-flex align-items-center"
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="1000"
                >
                  <Box
                    width="30px"
                    minWidth="30px"
                    height="30px"
                    minHeight="30px"
                    bg="warning"
                    color="red"
                    borderRadius="50%"
                    className="d-flex align-items-center justify-content-center"
                    mr={3}
                  >
                    <i className="icon icon-check-2"></i>
                  </Box>
                  <Text color="light" fontSize={2} lineHeight="1.25">
                    Winding Brook Farm has posted new availability
                  </Text>
                </TopCard>

                <BottomCard
                  bg="primary"
                  p="18px"
                  borderRadius={8}
                  className="d-flex align-items-center text-left"
                  data-aos="fade-left"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="1000"
                >
                  <Box
                    width="30px"
                    minWidth="30px"
                    height="30px"
                    minHeight="30px"
                    bg="infoLight"
                    color="primary"
                    borderRadius="50%"
                    className="d-flex align-items-center justify-content-center"
                    mr={3}
                  >
                    <i className="icon icon-check-2"></i>
                  </Box>
                  <Text color="light" fontSize={2} lineHeight="1.25">
                    {`Oak & Poppy has placed an order`}
                  </Text>
                </BottomCard>
              </div>
            </Col>
            <Col lg="7" className="order-lg-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                  <Text mb={3}>
                    At Petaler, we are here to make local flowers more
                    accessible.
                  </Text>
                  <Title variant="hero">
                    Connecting flower farmers and florists to save time buying
                    and selling local flowers.
                  </Title>
                  <Text mb={3}>Find us in your app store!</Text>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
