import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Carousel } from "react-bootstrap";

import { Title, Section, Text, Box } from "../../components/Core";
import { device } from "../../utils";

import searchBuyers from "../../assets/image/png/farmers_searchbuyers.png";
import accountDetails from "../../assets/image/png/farmers_accountdetails.png";
import schedule from "../../assets/image/png/farmers_schedule.png";
import orderDetails from "../../assets/image/png/farmers_orderdetails.png";
import inventory from "../../assets/image/png/farmers_inventory.png";
import fulfillment from "../../assets/image/png/farmers_fulfillment.png";

const ContentCard = ({ title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box>
      <Title variant="subtitle" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small" fontSize="18px">
        {children}
      </Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`
  -webkit-filter: drop-shadow(
    ${({ theme }) => `0 24px 28px ${theme.colors.shadow}`}
  );
  filter: drop-shadow(${({ theme }) => `0 24px 24px ${theme.colors.shadow}`});
  margin-horizontal: 34px;
  align-self: center;
  margin-top: 24px;
  margin-bottom: 74px;
  max-width: 60%;
  @media ${device.sm} {
    max-width: 50%;
    -webkit-filter: drop-shadow(
      ${({ theme }) => `0 12px 14px ${theme.colors.shadow}`}
    );
    filter: drop-shadow(${({ theme }) => `0 12px 14px ${theme.colors.shadow}`});
  }
  @media ${device.md} {
    max-width: 60%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
`;

const StyledCarouselItem = styled(Carousel.Item)`
  text-align: center;
`;

const Content1 = () => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section
      bg="#E1E6DF"
      className="position-relative"
      pt={["50px", null, "130px", null, "230px"]}
    >
      <Container>
        <Row className="align-items-center">
          <Col
            lg="6"
            className=" position-static order-lg-2 justify-content-center"
          >
            <Carousel className="d-flex justify-content-center">
              <StyledCarouselItem
                interval={11000}
                className="img-fluid text-center  w-100 "
              >
                <ImgStyled src={searchBuyers} alt="" />
              </StyledCarouselItem>
              <StyledCarouselItem
                interval={11000}
                className="img-fluid text-center  w-100 "
              >
                <ImgStyled src={inventory} alt="" />
              </StyledCarouselItem>
              <Carousel.Item
                interval={11000}
                className="img-fluid text-center  w-100"
              >
                <ImgStyled src={schedule} alt="" />
              </Carousel.Item>
              <Carousel.Item
                interval={11000}
                className="img-fluid text-center  w-100"
              >
                <ImgStyled src={orderDetails} alt="" />
              </Carousel.Item>
              <Carousel.Item
                interval={11000}
                className="img-fluid text-center  w-100"
              >
                <ImgStyled src={fulfillment} alt="" />
              </Carousel.Item>
              <Carousel.Item
                interval={11000}
                className="img-fluid text-center  w-100"
              >
                <ImgStyled src={accountDetails} alt="" />
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col lg="6" className="order-lg-1 mt-5 mt-lg-0">
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
                <Title variant="subtitle" mb={4} letterSpacing={1.2}>
                  FOR FARMERS
                </Title>
                <Title>Get back to growing.</Title>
                <Box mt={3} pt={3}>
                  <ContentCard title="An All-in-One Platform" mb={4}>
                    List your availability, set your prices and preferences,
                    accept payments, and fulfill orders all in one place. No
                    more juggling availability lists, schedule, and
                    back-and-forth emails.
                  </ContentCard>
                  <ContentCard title="Sell More Flowers" mb={4}>
                    Expand your customer base beyond your inbox. We'll help you
                    manage your existing relationships, form new ones, and make
                    it effortless to purchase your flowers. More ease means more
                    flowers into more hands.
                  </ContentCard>
                  <ContentCard title="Efficient Workflow">
                    We know you have a lot on your plate, and we'd like to help.
                    We'll connect you with buyers, handle payments, and
                    seamlessly update your inventory so you can have more time
                    doing what you love - growing flowers.
                  </ContentCard>
                </Box>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content1;
