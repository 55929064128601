import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

import imgMobile from "../../assets/image/png/searchfarmers.png";

const ContentCard = ({ title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box>
      <Title variant="subtitle" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small" fontSize="18px">
        {children}
      </Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`
  -webkit-filter: drop-shadow(
    ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`}
  );
  filter: drop-shadow(${({ theme }) => `0 52px 54px ${theme.colors.shadow}`});
  max-width: 70%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    margin-left: 50px;
  }
`;

const Content2 = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section bg="#E1E6DF">
      <Container>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 mb-lg-0">
            <div
              className="pl-5"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="" className="img-fluid" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className="pt-5 pt-lg-0">
              <Title variant="subtitle" mb={4} letterSpacing={1.2}>
                FOR FLORISTS AND DESIGNERS
              </Title>
              <Title>
                Find and buy local{" "}
                <br className="d-none d-sm-block d-md-none d-xl-block" />
                flowers effortlessly.
              </Title>
              <Box mt={4} pt={3}>
                <ContentCard
                  title="Your Local Flower Market, Wherever You Are"
                  mb={4}
                >
                  We've simplified the process of buying local flowers by
                  creating a single platform where you can browse and purchase
                  available flowers from growers in your area.
                </ContentCard>
                <ContentCard title="Local Flowers are Better Flowers" mb={4}>
                  By effortless connecting you with local flower growers, you
                  have access to gorgeous and unique varieties that elevate your
                  designs - harvested with care, and not shipped thousands of
                  miles in a box.
                </ContentCard>
                <ContentCard title="Know Your Farmer">
                  Buying local means you get to know who is growing your
                  flowers. Your choice to source your flowers sustainably is a
                  choice to support and invest in your own community.
                </ContentCard>
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
