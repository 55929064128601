import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import {
  Title,
  Button,
  Section,
  Box,
  Text,
  Input,
} from "../../components/Core";
import addToMailchimp from "gatsby-plugin-mailchimp";

import svgCurve from "../../assets/image/svg/l1-curve-cta.svg";

const LeftCard = styled(Box)`
  position: absolute;
  top: 0;
  left: 0px;
`;

const RightCard = styled(Box)`
  position: absolute;
  top: 0;
  right: -275px;
`;

const CTA = () => {
  const [submitted, setSubmitted] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const onSubmit = async (e) => {
    e.preventDefault();
    const [first, ...rest] = name.split(" ");
    const last = rest.join(" ");
    console.log(first, last); // good, luck_buddy

    const result = await addToMailchimp(email, {
      FNAME: first,
      LNAME: last,
      MESSAGE: "Contact Submission",
    });

    setSubmitted(result.msg);
  };

  const showThankYou = (
    <Text
      color="light"
      dangerouslySetInnerHTML={{
        __html: `<Text color="light">${submitted}</Text>`,
      }}
    />
  );

  const showForm = (
    <form method="post">
      <Box mb={3}>
        <Input
          type="text"
          placeholder="Your name"
          name="name"
          id="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </Box>
      <Box mb={3}>
        <Input
          type="email"
          placeholder="Your email"
          name="email"
          id="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </Box>

      <Button width="100%" type="submit" borderRadius={10} onClick={onSubmit}>
        Keep me in the loop
      </Button>
    </form>
  );

  return (
    <>
      {/* <!-- CTA section --> */}
      <Section bg="dark" className="position-relative">
        <LeftCard
          data-aos="fade-right"
          data-aos-duration="750"
          data-aos-once="true"
        ></LeftCard>
        <RightCard>
          <img src={svgCurve} alt="" className="img-fluid" />
        </RightCard>
        <Container>
          <Row className="justify-content-center">
            <Col lg="7" xl="6">
              <Box mb={5} className="text-center">
                <Title color="light">Stay in the know</Title>
              </Box>
              {submitted ? showThankYou : showForm}
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default CTA;
