import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

import imgL2HeroBg from "../../assets/image/jpeg/seedstarting.jpg";

const SectionStyled = styled(Section)`
  border-bottom: 1px solid #ededf4;
  padding-bottom: 30px;
  padding-top: 80px;

  background: url(${imgL2HeroBg}) no-repeat;
  background-size: cover;
  background-position: bottom left;

  @media ${device.lg} {
    background-position: center;
  }
`;

const Fact = () => (
  <>
    {/* <!-- Fact section 1 --> */}
    <SectionStyled bg="secondary">
      <Container>
        <Row>
          <Col md="9" className="mb-5">
            <Box>
              <Text mb={48} color="warning">
                At Petaler, we make it easier for flower farmers to manage their
                business and sell more flowers. Our simple all-in-one platform,
                handles your availability lists, orders, schedules, and payments
                - while promoting your flowers on our online flower market.
                Petaler is designed to take a couple things off your plate, and
                make it easier to sell more flowers so you can get back to
                growing.
              </Text>
              <Title color="warning">
                The floral industry is ready for a change.
              </Title>
            </Box>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Fact;
